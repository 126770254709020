import { getCommonParamsOptions, getParamsOptionsAsync } from '@/utils/common'

export const baseViewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.application-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'author',
      type: 'input',
      label: 'file.author',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'company',
      type: 'input',
      label: 'file.company',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'appType',
      type: 'select',
      label: 'file.application-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('app_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantWhitelist',
      type: 'treeSelect',
      label: 'Tenant Whitelist',
      isResolveGlobalParams: true,
      otherOptions: {
        props: {
          multiple: true
        },
        disabled: true
      },
      options: [],
      placeholder: ' ',
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        disabled: true
      },
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      }
    },
    {
      field: 'iconUrl',
      type: 'imgUpload',
      label: 'common.app-icon',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'screenShotUrl',
      type: 'pictureList',
      label: 'common.app-screen-shots',
      otherOptions: {
        disabled: true
      },
      colLayout: {
        xl: 16,
        lg: 16,
        md: 24,
        sm: 24,
        xs: 24
      }
    }
  ]
}
